:root {
    --yellow: #fccd35;
    --pink: #ff19e9;
    --green: #00cc00;
    --white: #ffffff;
    --light-grey: #b1b1b1;
    --dark-grey: #2b2b2b;
    --black: #000000;
    --clr-primary-400: #f5f0f0;
    --clr-secondary-400: 217 19% 35%;
    --clr-shadow: #A6B5D2;
    --clr-bg: #ECEFF5;
    
  
  
    --fw-400: 500;
    --fw-700: 600;
  
    --fs-300: 0.6875rem;
    --fs-400: 0.8125rem;
    --fs-500: 1.25rem;
  }

section {
    border: 1 px solid blue;
    width: 100% ;
    float: left;
 }
body {
  display: grid;  
  place-content: center;
  min-height: 100vh;
  background-color: var(--clr-bg);
}

.left{
    float: left;
    
}

.text{
    color: #F0F8FF;
}

.socials1 {
  fill: #000000;
}

.socials2 {
  fill: #000000;
}

.header {
    position: sticky;
    top: 0;
    z-index: 5;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 1em;
    padding: 50px;
    background: var(--clr-bg);
    color: #f1f1f1;
  }

  .header2 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    padding-bottom: 100px;
    box-shadow: 0px 0px 10px #A6B5D2;

    justify-content: center;
    background: var(--clr-bg);
    
    
  }

  .blog {
      font-size: large;
      box-sizing: border-box;
      
  }

  .post {
      display: grid;
      grid-template-columns: 
      minmax(1.2rem, 1fr)
      minmax(auto, 60ch)
      minmax(1.2rem, 1fr);
      font-family:'Roboto', sans-serif;
      font-weight: 300;
      font-size: larger;
      padding-top: 1rem;
  }

  p, h1, h2 {
        grid-column: 2; 
        
  }
 

  img {
      
      justify-self: center;
  }

  .minter-boxes {
    background-color: white;

  }

  .box-grid {
    display: grid;
    gap: 1.5rem;
    
  }
  .box-grid2 {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: minmax(auto, 75ch);
  }
  .img-logo {
    display: grid;
    grid-row: 1;
    max-width: 90vw;

  }

  .img-col2 {
      grid-column: 1;
      max-width: 90vw;
  }

  .img-example {
      display: grid;
      grid-row-start: 3;
      grid-column-start: 1;
      width: 90vw;
      max-width: 500px;
      padding-bottom: 1.5rem;

    }
    .img-headshot {
        display: grid;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
        grid-column-start: 1;

        max-width: 40%;

        border: 0.5rem solid var(--clr-bg);
        border-radius: 50%;
        box-shadow: 0px 0px 10px var(--clr-shadow);
      }
  @media (min-width: 50em) {
    .box-grid {
    grid-template-columns: 1fr 1fr;
    
    }
    .socials1 {
      fill: var(--clr-bg);
    }
    
    .img-logo {
        grid-column: span 2; 
    }
    .img-example {
        grid-column: span 2; 
    }
   
  }

  .testimonial {
    font-size: large;
    font-family:'Roboto', sans-serif;
    padding: 2rem;
    background-color: #F0F8FF;
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 1rem var(--clr-shadow);
  }

  .post2 {
   
    grid-column-start: 1;
    font-size: large;
    font-family:'Roboto', sans-serif;
    padding: 2rem;
    background-color: #F0F8FF;
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 1rem var(--clr-shadow);
    line-height: 1.5; 
  }

  .image-gallery-slide img {
    width: 400px;
    
  }

  .gallery{
      display: grid;
      grid-template-columns: auto;
      gap: 1.5em;
      margin-top: 7em;
      margin-bottom: 1.5em;
  }

  .pictureBox {
      
    position: relative;
    width: 100%;
    height: 0%;
    padding-bottom: 100%;
   
    

  }

  .content {
      position: absolute;
      top: 0;
      left: 0;
      
  }
 

  

